export function trackCTAClick() {
	// @ts-expect-error
	window?.datafast('cta_clicked', {
		description: `The CTA on the landing page was clicked.`,
	})
}

export function trackCreateAccountClick() {
	// @ts-expect-error
	window?.datafast('create_account_clicked', {
		description: 'The Create Account button was clicked',
	})
}
